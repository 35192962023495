<template>
  <b-card-header header-tag="header" role="tab" @click="$emit('load')">
    <b-container>
      <b-row class="align-items-end">
        <b-col cols="8" class="d-flex align-items-center pl-0 pr-4">
          <b-link class="drag">
            <b-icon icon="arrows-expand" font-scale="1.3" style="color: #ccc" />
          </b-link>
          <div class="d-flex flex-column a-question-header">
            <span>Otázka č.{{ indexQ + 1 }}</span>
            <strong class="mt-2">{{ question.name }}</strong>
          </div>
        </b-col>
        <b-col class="pr-0">
          <b-row>
            <b-col v-if="question.visible" cols="8">
              <b-form-group id="anwsers-type" class="d-flex" label="Typy odpovedí" @click.stop>
                <b-form-select v-model="question.type" :options="options" size="sm" />
              </b-form-group>
            </b-col>
            <b-col class="d-flex justify-content-end align-items-end">
              <b-link class="a-icon-wrapper rounded-circle" href="#" @click.stop="deleteQuestion(indexQ)">
                <img src="@/plugins/app/_theme/icon/delete.svg" />
              </b-link>
              <b-link class="a-icon-wrapper rounded-circle --toggle ml-2" href="#" v-b-toggle="'accordion-' + indexQ" @click.stop>
                <b-icon icon="triangle-fill" :rotate="question.visible ? '0' : '180'" />
              </b-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </b-card-header>
</template>

<script>
export default {
  props: {
    question: { required: true },
    indexQ: { required: true }
  },

  data() {
    return {
      options: [
        { value: 'choices', text: 'Typ: Možnosti' },
        { value: 'field', text: 'Typ: Text' },
        { value: 'multiselect', text: 'Typ: Viacnásobný výber' },
      ]
    }
  },

  methods: {
    async deleteQuestion() {
      const questionNumber = this.indexQ + 1
      const options = {
          title: `Vymazať otázku č.${questionNumber}?`,
          okTitle: 'Vymazať',
          okVariant: 'a-primary',
          cancelTitle: 'Zrušiť',
          cancelVariant: 'a-primary--outline',
          buttonSize: 'sm',
          hideHeaderClose: false
        }

      try {
        const confirm = await this.$bvModal.msgBoxConfirm(
          `Naozaj chcete vymazať otázku č.${questionNumber}?`, 
          options
        )
        if (!confirm) {
          return
        }

        this.$emit('delete', this.indexQ)
      } catch(err) {
        console.error('delete question problem', err)
      }
    },
  }
}
</script>

<style lang="sass" scoped>

</style>
